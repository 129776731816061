<template>
    <div>
        <div
            v-if="listCandidates == null && !loadError"
            class="text-center pa-5">
            <v-progress-circular
                indeterminate
                color="primary"/>
        </div>
        <div
            v-else-if="listCandidates == null && loadError"
            class="text-center pa-5">
            <v-alert
                type="warning"
                class="d-inline-block">
                Une erreur est survenu durant le chargement des données
            </v-alert>
        </div>
        <v-card
            v-else
            class="overflow-hidden elevation-0">
            <v-toolbar
                flat
                color="secondary"
                dark>
                <v-icon class="mr-2">mdi-account-multiple</v-icon>
                <v-toolbar-title class="font-weight-light">
                    Candidats
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialogCreate"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            light
                            v-bind="attrs"
                            v-on="on">
                            Ajouter un candidat
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                Ajout d'un candidat
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-form
                                    ref="form"
                                    v-model="formValid">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6">
                                            <v-text-field
                                                v-model="editedItem.lastname"
                                                :rules="[v => !!v || 'Un nom est requis']"
                                                label="Nom"/>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6">
                                            <v-text-field
                                                v-model="editedItem.firstname"
                                                :rules="[v => !!v || 'Un prénom est requis']"
                                                label="Prénom"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="editedItem.email"
                                                label="Email"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="editedItem.fonction"
                                                label="Fonction"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-checkbox
                                                v-model="editedItem.handicap"
                                                label="Handicap"/>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-if="editedItem.handicap"
                                                v-model="editedItem.handicapStr"
                                                label="Handicap"/>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="close">
                                Annuler
                            </v-btn>
                            <v-btn
                                :disabled="!formValid"
                                color="primary"
                                text
                                @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-card-text>
                <v-autocomplete
                    v-model="selectedCandidates"
                    :items="listCandidates"
                    :item-text="item => (item.lastname == null ? item.lastname : item.lastname.toUpperCase()) + ' ' + item.firstname"
                    item-value="uuid"
                    label="Candidats"
                    multiple>
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.lastname.toUpperCase() }} {{ data.item.firstname }}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.email != null">{{ data.item.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "SelectCandidates",
    props: ['value', 'companyUuid'],
    data: function(){
        return {
            loadError: false,
            listCandidates: null,

            dialogCreate: false,

            formValid: false,

            editedUuid: null,
            editedItem: {
                companyUuid: this.companyUuid,
                lastname: '',
                firstname: '',
                fonction: '',
                handicap: false,
                handicapStr: '',
            },
            defaultItem: {
                companyUuid: this.companyUuid,
                lastname: '',
                firstname: '',
                fonction: '',
                handicap: false,
                handicapStr: '',
            },
        }
    },
    computed: {
        selectedCandidates: {
            get() {
                if(this.value === null)
                    return []

                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        close () {
            this.dialogCreate = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedUuid = null
            })
        },
        save () {
            this.$refs.form.validate()

            this.$req
                .post('candidates', this.editedItem)
                .then(response => {
                    this.listCandidates.push(response)
                    this.selectedCandidates.push(response.uuid)
                    this.$emit('input', this.selectedCandidates)
                    this.close();
                })
        },
    },
    mounted: function() {
        this.$req
            .get('companies/' + this.companyUuid + '/candidates')
            .then(response => this.listCandidates = response)
            .catch(() => this.loadError = true)
    }
}
</script>