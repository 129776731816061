<template>
    <v-card>
        <v-card-title>
            <span class="headline">
                Édition d'un candidat
            </span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="formValid">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                v-model="editedItem.lastname"
                                :rules="[v => !!v || 'Un nom est requis']"
                                label="Nom"/>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                v-model="editedItem.firstname"
                                :rules="[v => !!v || 'Un prénom est requis']"
                                label="Prénom"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="editedItem.email"
                                label="Email"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="editedItem.fonction"
                                label="Fonction"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                v-model="editedItem.handicap"
                                label="Handicap"/>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-if="editedItem.handicap"
                                v-model="editedItem.handicapStr"
                                label="Handicap"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="close">
                Annuler
            </v-btn>
            <v-btn
                :disabled="!formValid"
                color="primary"
                text
                @click="save">
                Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "EditCandidate",
    props: ['companyUuid', 'editedItem'],
    data: function () {
        return {
            formValid: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$refs.form.validate()

            this.$req
                .put('candidates/' + this.editedItem.uuid, this.editedItem)
                .then(response => {
                    console.log(response);
                    this.$emit('update', response)
                })
        },
    },
}
</script>